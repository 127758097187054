import React, { FC, useContext, useEffect } from "react";
import { Heading, Stack, Box, IconButton, Tag } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { LayoutContext } from "../../components/layout";
import { graphql, Link, PageProps } from "gatsby";
import { BlockFragment, PostTemplateQuery } from "../../../graphql-types";
import { FluidObject } from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
import { Blocks } from "../../components/blocks";
import { Container } from "../../components/container";
import { Footer } from "../../components/footer";
import { BrochureFooter } from "../../components/brochure/footer";
import { motion } from "framer-motion";

export const query = graphql`
  query PostTemplate($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      title
      date(formatString: "LL", locale: "nl")
      blocks {
        ...Block
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

const PostTemplate: FC<PageProps<PostTemplateQuery>> = props => {
  const { data } = props;
  const post = data.wpPost;

  const { mapMachine } = useContext(LayoutContext);
  const [, mapSend] = mapMachine as NonNullable<typeof mapMachine>;

  useEffect(() => {
    mapSend("HIDE_MAP");
  }, [mapSend]);

  return (
    <motion.div
      key={props.path}
      initial={{ opacity: 0, x: "100%" }}
      animate={{ opacity: 1, x: "0%" }}
      exit={{ opacity: 0, x: "-100%" }}
      transition={{ duration: 0.5 }}
    >
      <main>
        <BackgroundImage
          fluid={[
            `linear-gradient(rgba(0, 0, 0, 0.666), rgba(0, 0, 0, 0.404588))`,
            ...(post?.featuredImage
              ? [
                  post.featuredImage.node?.localFile?.childImageSharp
                    ?.fluid as FluidObject,
                ]
              : []),
          ]}
        >
          <Box pt={48} pb={16}>
            <Container maxW="xl">
              <Stack align="flex-start">
                <Link to="/updates-en-nieuws/">
                  <IconButton
                    as="div"
                    aria-label="terug naar overzicht"
                    icon={<ArrowBackIcon />}
                  />
                </Link>

                <Heading
                  color="white"
                  textStyle="korolevCompressed"
                  fontSize={{ base: "5xl", md: "7xl" }}
                >
                  {post?.title}
                </Heading>

                <Tag bgColor="gold" size="lg">
                  {post?.date}
                </Tag>
              </Stack>
            </Container>
          </Box>
        </BackgroundImage>

        <Blocks blocks={post?.blocks as BlockFragment[]} />
      </main>

      <footer>
        <BrochureFooter />
        <Footer />
      </footer>
    </motion.div>
  );
};

export default PostTemplate;
